@use "./z-index";
@use "src/styles/theming/colors";

@mixin button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  z-index: z-index.$low;

  :hover {
    color: colors.$primary;
  }
}
