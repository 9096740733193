@use "@angular/material" as mat;
@use "sass:map";

@mixin app-table-theme($theme) {
  @include mat.legacy-table-theme($theme);

  $background: map.get($theme, background);

  table {
    tbody,
    thead,
    tfoot {
      td.mat-cell,
      th.mat-header-cell,
      td.mat-footer-cell {
        border-bottom-width: 2px;
        color: inherit;
        padding: 0.75em 1em 0.75em 0;

        &:first-child {
          padding-left: 1em;
        }
      }

      tr.mat-header-row,
      tr.mat-footer-row {
        height: auto;
        background-color: mat.get-color-from-palette($background, table-footer-background);
      }
    }
  }
}
