@use "sass:map";

$white: #ffffff;
$black: #000000;

// Storebrand colors
// https://storebrand.brandmaster.com/brandcenter/no/storebrandhub/component/default/40983
$charcoal: #191919;
$red-4: #da291c;
$red-5: #bf0900;
$red-6: #aa0600;
$red-7: #910300;
$blue-1: #d2f5f9;
$blue-2: #7acddc;
$blue-3: #0191a5;
$blue-4: #003f4a;
$green-1: #f5f9f0;
$green-2: #e8f3e1;
$green-3: #c6e7c1;
$green-5: #5f956a;
$green-7: #2b412e;
$beige-2: #fdf4e6;
$beige-3: #f5e4c7;
$beige-4: #f4dbb8;
$beige-5: #efd5a9;
$beige-6: #c7aa7f;
$beige-7: #957851;
$beige-7-darkened: #8c6e46;
$beige-8: #5b462b;
$beige-9: #4f3b22;

// Storeblocks colors
// https://www.figma.com/file/IXxZHItqkDyt9SivAzurMX/Storeblocks
$primary: $red-5;
$primary-variant: $red-6;
$primary-pressed: $red-7;
$on-primary: $white;
$background: $white;
$on-background: $charcoal;
$inactive: #666666;
$surface: $beige-2;
$surface-border: $beige-3;
$surface-hover: $beige-4;
$surface-pressed: $beige-5;
$surface-variant: $green-1;
$surface-variant-border: $green-2;
$on-surface: $charcoal;
$border: rgba(25, 25, 25, 0.16);
$unselected: #dddddd;
$selection: $beige-8;
$selection-hover: $beige-7-darkened;
$on-selection: $white;
$info-background: #cbf3f0;
$info-accent: #2a8eac;
$success-background: $green-2;
$success-accent: #4a966e;
$warning-background: #fdf6cf;
$warning-accent: #c17b0b;
$error-background: #fee6db;
$error-accent: #e36a31;
$on-alert: $charcoal;
$disabled: #757575;
$disabled-variant: #cccccc;
$scrim: rgba(145, 97, 97, 0.6);
$overlay-hover-darken: rgba(25, 25, 25, 0.05);
$overlay-hover-lighten: rgba(255, 255, 255, 0.15);
$overlay-pressed-darken: rgba(25, 25, 25, 0.1);
$illustration-red-4: $red-4;
$illustration-red-5: $red-5;
$illustration-charcoal: $charcoal;
$icon-red-4: $red-4;
$icon-charcoal: $charcoal;
$graph-00: #e6e6e6;
$graph-06: $blue-3;
$caption-background: rgba(25, 25, 25, 0.75);
$on-caption-background: $white;

// Legacy smart pensjon colors
// https://www.figma.com/file/9yxwilxG8VY1bXmHZNmAFu/SP-%E2%80%94-%F0%9F%A7%A9-SP-Styles
$sp-red-10: #fff0f0;
$sp-red-20: #fddddd;
$sp-red-30: #ffb3b3;
$sp-red-50: $red-4;
$sp-red-60: #bb1b18;
$sp-green-20: #ecf2c2;
$sp-green-50: #a2c14e;
$sp-green-60: #819f2b;
$sp-green-70: #6c8524;
$sp-grey-0: $white;
$sp-grey-10: #f9f9f9;
$sp-grey-10: #f2f2f2;
$sp-grey-30: #e6e6e6;
$sp-grey-40: #cccccc;
$sp-grey-50: #a6a6a6;
$sp-grey-60: #666666;
$sp-grey-70: #404040;
$sp-grey-80: #282828;
$sp-sky-20: #ceeeec;
$sp-sky-30: #b0dfdb;
$sp-yellow-20: #fcf0be;
$sp-yellow-30: #fce06d;
$sp-yellow-60: #b69d03;
$sp-aqua-20: #e4f3f5;
$sp-aqua-30: #d1ebee;
$sp-aqua-70: #1c818d;
$sp-moss-60: #5f6e57;
$sp-brown-10: #faf5ef;
$sp-brown-20: #efe8df;
$sp-brown-30: #e6d1b8;
$sp-brown-40: #d6bb9b;
$sp-brown-50: #c4a587;
$sp-brown-60: #9a8269;
$sp-brown-70: #776654;
$sp-brown-80: #55483a;
$sp-brown-90: #392e25;

// Smart pensjon colors
$error: $red-4;
$warning: #f49342;
$success: $sp-green-70;
$divider: rgba(black, 0.12);
$background: $sp-brown-10;
$panel-background: #fefbf7;
