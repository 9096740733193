@use "@angular/material" as mat;
@use "sass:map";

@mixin app-step-buttons-theme($theme) {
  $foreground: map.get($theme, foreground);

  app-step-buttons {
    border-color: mat.get-color-from-palette($foreground, border-light);
  }
}
