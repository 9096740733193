@use "@angular/material" as mat;
@use "palette";

$stb-typography: mat.define-legacy-typography-config(
  $font-family: "'Open Sans', sans-serif",
  $headline: mat.define-typography-level(32px, 48px, 400),
  $title: mat.define-typography-level(20px, 24px, 500),
  $body-1: mat.define-typography-level(16px, 24px, 400),
  $subheading-1: mat.define-typography-level(16px, 24px, 400),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($stb-typography);`
@include mat.all-legacy-component-typographies($stb-typography);
@include mat.legacy-core();

/*************** Theme variables ***************/
$stb-primary: mat.define-palette(palette.$ox-beige, 700);
$stb-accent: mat.define-palette(palette.$ox-beige, 600);

$stb-input-primary: mat.define-palette(palette.$ox-beige, 500);
$stb-input-accent: mat.define-palette(palette.$stb-grey, 300);

$grey-primary: mat.define-palette(palette.$stb-grey, 700);
$grey-disabled: mat.define-palette(palette.$stb-grey, 500);
$grey-accent: mat.define-palette(palette.$stb-grey, 300);
$grey-subtle: mat.define-palette(palette.$stb-grey, 100);

$red-primary: mat.define-palette(palette.$stb-red, 500, 100, 700);
$red-error: mat.define-palette(palette.$stb-red, 700);

$brown-primary: mat.define-palette(palette.$stb-brown);
$green-primary: mat.define-palette(palette.$stb-support, 700, 100, 700);

$ox-beige: mat.define-palette(palette.$ox-beige, 400);
$ox-beige-light: mat.define-palette(palette.$ox-beige, 200);
$ox-beige-lighter: mat.define-palette(palette.$ox-beige, 100);

$brown-primary: mat.define-palette(palette.$stb-brown);

$green-primary: mat.define-palette(palette.$stb-green);

$stb-input-theme: mat.define-light-theme(
  (
    color: (
      primary: $stb-input-primary,
      accent: $stb-input-accent,
    ),
  )
);
$stb-radio-theme: mat.define-light-theme(
  (
    color: (
      primary: $grey-primary,
      accent: $grey-primary,
    ),
  )
);
$stb-spinner-theme: mat.define-light-theme(
  (
    color: (
      primary: $stb-primary,
      accent: $ox-beige,
    ),
  )
);
$stb-red-theme: mat.define-light-theme(
  (
    color: (
      primary: $red-primary,
      accent: $grey-accent,
    ),
  )
);
$stb-grey-theme: mat.define-light-theme(
  (
    color: (
      primary: $grey-primary,
      accent: $grey-accent,
    ),
  )
);
$stb-theme: mat.define-light-theme(
  (
    color: (
      primary: $stb-primary,
      accent: $stb-accent,
      warn: $red-primary,
    ),
  )
);
$stb-button-theme: mat.define-light-theme(
  (
    color: (
      primary: $green-primary,
      accent: $green-primary,
      warn: $red-primary,
    ),
  )
);
$stb-onboarding-theme: mat.define-light-theme(
  (
    color: (
      primary: $stb-primary,
      accent: palette.$stb-support,
    ),
  )
);
$stb-p88-primary-theme: mat.define-light-theme(
  (
    color: (
      primary: $brown-primary,
      accent: $green-primary,
    ),
  )
);
$stb-p88-secondary-theme: mat.define-light-theme(
  (
    color: (
      primary: $brown-primary,
      accent: $red-primary,
    ),
  )
);
$stb-p88-tertiary-theme: mat.define-light-theme(
  (
    color: (
      primary: $brown-primary,
      accent: palette.$stb-aqua,
    ),
  )
);

$stb-ox-primary-theme: mat.define-light-theme(
  (
    color: (
      primary: palette.$ox-beige,
      accent: palette.$ox-green,
      warn: palette.$ox-blue,
    ),
  )
);

/*************** Material themes ***************/
@include mat.legacy-checkbox-theme($stb-p88-primary-theme);
@include mat.bottom-sheet-theme($stb-theme);
@include mat.button-toggle-theme($stb-button-theme);
@include mat.legacy-core-theme($stb-theme);
@include mat.legacy-dialog-theme($stb-theme);
@include mat.legacy-input-theme($stb-input-theme);
@include mat.legacy-progress-bar-theme(
  mat.define-light-theme(
    (
      color: (
        primary: $stb-primary,
        accent: mat.define-palette(palette.$ox-red, 400),
      ),
    )
  )
);
@include mat.legacy-progress-spinner-theme($stb-spinner-theme);
@include mat.legacy-radio-theme($stb-radio-theme);
@include mat.legacy-select-theme($stb-theme);
@include mat.legacy-slide-toggle-theme($stb-theme);
@include mat.legacy-snack-bar-theme($stb-theme);

// @include mat.badge-theme($stb-core-theme);
// @include mat.autocomplete-theme($theme);
// @include mat.badge-theme($theme);
// @include mat.divider-theme($theme);
// @include mat.grid-list-theme($theme);
// @include mat.icon-theme($theme);
// @include mat.menu-theme($theme);
// @include mat.paginator-theme($theme);
// @include mat.sidenav-theme($theme);
// @include mat.slider-theme($theme);
// @include mat.stepper-theme($theme);
// @include mat.sort-theme($theme);
// @include mat.toolbar-theme($theme);
// @include mat.tree-theme($theme);

/*************** Custom app themes ***************/
@import "components/account-page-theme";
@import "components/accumulation-page-theme";
@import "components/active-employment-card-theme";
@import "components/add-engagement-buttons-theme";
@import "components/add-engagement-theme";
@import "components/advisor-page-theme";
@import "components/agreement-payout-guide-theme";
@import "components/amount-card-theme";
@import "components/bank-accounts-auth-step-up-theme";
@import "components/button-theme";
@import "components/button-toggle-group-theme";
@import "components/card-theme";
@import "components/checkbox-theme";
@import "components/chips-theme";
@import "components/concept-page-theme";
@import "components/consensual-submit-theme";
@import "components/consent-card-theme";
@import "components/contract-theme";
@import "components/default-contact-page-theme";
@import "components/engagement-alert-message-theme";
@import "components/engagement-details-public-pension-theme";
@import "components/engagement-body-theme";
@import "components/engagement-list-public-theme";
@import "components/engagement-details-theme";

@import "components/engagement-theme";
@import "components/expandable-alert-theme";
@import "components/expandable-data-alerts-theme";
@import "components/expansion-panel-theme";
@import "components/form-field-theme";
@import "components/introduction-step-theme";
@import "components/lens-radio-button-theme";
@import "components/list-theme";
@import "components/mat-accordion-stb-styling-theme";
@import "components/mat-dialog-theme";
@import "components/menu-theme";
@import "components/nav-progress-theme";
@import "components/number-circle-theme";
@import "components/percentage-total-pension-change-theme";
@import "components/progress-theme";
@import "components/public-pension-contract-badge-theme";
@import "components/public-pension-contract-theme";
@import "components/purchase-assistance-consent-theme";
@import "components/quote-hero-theme";
@import "components/shortcuts-theme";
@import "components/simulation-age-theme";
@import "components/simulation-params-panel-theme";
@import "components/skeleton-theme";
@import "components/smart-account-campaign-theme";
@import "components/smart-account-theme";
@import "components/step-buttons-theme";
@import "components/summary-page-theme";
@import "components/table-theme";
@import "components/tabs-theme";
@import "components/tip-banner-theme";
@import "components/tip-icon-theme";
@import "components/tooltip-theme";
@import "components/transactions-theme";
@import "components/typography-theme";
@import "components/video-overlay-theme";
@import "components/withdrawal-order-engagement-details-theme";
@import "components/external-engagement-theme";
@import "overrides/sherpherd-theme";
@import "components/my-pension-landing-page-theme";
@import "components/return-on-investment-card-theme";

@include app-account-page-theme($stb-ox-primary-theme);
@include app-accumulation-page-theme($stb-theme);
@include app-active-employment-card-theme($stb-ox-primary-theme);
@include app-add-engagement-buttons-theme($stb-p88-primary-theme);
@include app-add-engagement-theme($stb-p88-primary-theme);
@include app-advisor-page-theme($stb-theme);
@include app-agreement-payout-guide-theme($stb-ox-primary-theme);
@include app-amount-card-theme($stb-p88-primary-theme);
@include app-bank-accounts-auth-step-up-theme($stb-theme);
@include app-button-theme($stb-button-theme);
@include app-button-toggle-group-theme($stb-theme);
@include app-card-theme($stb-theme);
@include app-checkbox-theme($stb-ox-primary-theme);
@include app-chips-theme($stb-p88-primary-theme);
@include app-concept-page-theme($stb-p88-primary-theme);
@include app-consensual-submit-theme($stb-theme);
@include app-consent-card-theme($stb-p88-primary-theme);
@include app-contract-theme($stb-p88-primary-theme);
@include app-default-contact-page-theme($stb-p88-primary-theme);
@include app-engagement-alert-message-theme($stb-theme);
@include app-engagement-details-public-pension-theme($stb-p88-primary-theme);
@include app-engagement-body-theme($stb-p88-primary-theme);
@include app-engagement-list-public-theme($stb-p88-primary-theme);
@include app-engagement-details-theme($stb-p88-primary-theme);
@include app-engagement-theme($stb-p88-primary-theme);
@include app-expandable-alert-theme($stb-theme);
@include app-expandable-data-alerts-theme($stb-grey-theme);
@include app-expansion-panel-theme($stb-theme);
@include app-form-field-theme($stb-input-theme);
@include app-introduction-step-theme($stb-p88-primary-theme);
@include app-lens-radio-button-theme($stb-theme);
@include app-list-theme($stb-theme);
@include app-mat-accordion-stb-styling-theme($stb-theme);
@include app-mat-dialog-theme($stb-p88-primary-theme);
@include app-menu-theme($stb-red-theme);
@include app-nav-progress-theme($stb-p88-primary-theme);
@include app-number-circle-theme($stb-theme);
@include app-percentage-total-pension-change-theme($stb-p88-primary-theme);
@include app-progress-theme($stb-theme);
@include app-public-pension-contract-badge-theme($stb-p88-primary-theme);
@include app-public-pension-contract-theme($stb-p88-primary-theme);
@include app-purchase-assistance-consent-theme($stb-red-theme);
@include app-quote-hero-theme($stb-theme);
@include app-shortcuts-theme($stb-ox-primary-theme);
@include app-simulation-age-theme($stb-theme);
@include app-simulation-params-panel-theme($stb-p88-primary-theme);
@include app-skeleton-theme($stb-p88-primary-theme);
@include app-smart-account-campaign-theme($stb-ox-primary-theme);
@include app-smart-account-theme($stb-p88-primary-theme);
@include app-step-buttons-theme($stb-theme);
@include app-summary-page-theme($stb-theme);
@include app-table-theme($stb-theme);
@include app-tabs-theme($stb-red-theme);
@include app-tip-banner-theme($stb-p88-tertiary-theme);
@include app-tip-icon-theme($stb-theme);
@include app-tooltip-theme($stb-theme);
@include app-typography-theme($stb-red-theme);
@include app-transactions-theme($stb-ox-primary-theme);
@include app-video-overlay-theme($stb-red-theme);
@include app-withdrawal-order-engagement-details-theme($stb-p88-primary-theme);
@include app-external-engagement-theme($stb-theme);
@include shepherd-theme($stb-ox-primary-theme);
@include app-my-pension-landing-page-theme($stb-theme);
@include app-return-on-investment-card-theme($stb-ox-primary-theme);
