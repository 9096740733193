@use "@angular/material" as mat;
@use "sass:map";

@mixin app-expandable-data-alerts-theme($theme) {
  $background: map.get($theme, background);
  $primary: map.get($theme, primary);

  app-expandable-data-alerts {
    mat-expansion-panel {
      &.errors {
        &.mat-expansion-panel {
          background-color: mat.get-color-from-palette($background, background);
        }
      }
    }
  }
}
