@use "@angular/material" as mat;
@use "sass:map";

@mixin app-add-engagement-theme($theme) {
  app-external-engagement {
    app-lens-radio-group {
      app-lens-radio-button:not(:last-child) {
        margin-right: 1.5em;
      }
    }
  }
}
