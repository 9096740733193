@use "@angular/material" as mat;
@use "sass:map";

@mixin app-button-toggle-group-theme($theme) {
  $background: map.get($theme, background);
  mat-button-toggle-group {
    mat-button-toggle {
      min-width: 7.5em;
    }
    .mat-button-toggle-checked {
      background-color: mat.get-color-from-palette($background, button-group-toggle-background);
    }
  }
}
