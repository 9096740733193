@use "@angular/material" as mat;
@use "sass:map";

@mixin app-progress-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);

  .progress-oval {
    background-color: mat.get-color-from-palette($primary, 200);
  }

  .progress-gray {
    background-color: mat.get-color-from-palette($background, unselected-chip);
  }
}
