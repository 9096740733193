@use "@angular/material" as mat;
@use "sass:map";

@mixin app-introduction-step-theme($theme) {
  $background: map.get($theme, background);

  app-introduction-step {
    .wrapper-introduction {
      background-color: mat.get-color-from-palette($background, white);
    }
  }
}
