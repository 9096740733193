@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils";

@mixin app-button-theme($theme) {
  @include mat.legacy-button-theme($theme);
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);

  a,
  button {
    &.mat-button,
    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-stroked-button {
      @include utils.border-radius();
      white-space: normal;
      font-size: 1em;
      min-height: 2.5em;
      line-height: 1.5em;
      padding: 0.5em 1em;
      font-weight: 700;

      &[disabled] {
        cursor: not-allowed !important;
      }

      &:hover {
        filter: brightness(0.9);
      }

      &:active {
        filter: brightness(1);
      }
    }

    &.mat-stroked-button {
      padding: calc(0.5em - 1px) 1em; //compensate for 1px stroke lines
    }
  }

  .white-button {
    background-color: mat.get-color-from-palette($background, card);
  }

  .inline-button {
    width: auto;
  }
}
