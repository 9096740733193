@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/shadow";

@mixin app-percentage-total-pension-change-theme($theme) {
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);

  app-percentage-total-pension-change {
    .positive {
      color: mat.get-color-from-palette($accent, 500);
    }

    .negative {
      color: mat.get-color-from-palette($background, negative-amount);
    }
  }
}
