@use "@angular/material" as mat;
@use "sass:map";

@mixin app-consensual-submit-theme($theme) {
  $primary: map.get($theme, primary);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);

  .consent-row {
    border-color: mat.get-color-from-palette($background, background);

    .error {
      color: mat.get-color-from-palette($warn, 700);
    }
  }
}
