@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/breakpoint";

@mixin app-engagement-details-theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);

  app-engagement-details,
  app-contract-details-annual-return-pct {
    .summary-table {
      .summary-header {
        &:first-child {
          color: mat.get-color-from-palette($foreground, text);
        }
      }

      .summary-row {
        border-color: mat.get-color-from-palette($foreground, divider);

        .summary-label {
          color: mat.get-color-from-palette($foreground, secondary-text);
        }
      }
    }

    @include breakpoint.for-tablet {
      .summary-table.summary-table-responsive {
        .summary-row {
          .summary-label {
            color: mat.get-color-from-palette($foreground, text);
          }

          .summary-value {
            color: mat.get-color-from-palette($foreground, text-darker);
          }
        }
      }
    }
  }
}
