@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/shadow";

@mixin app-list-theme($theme) {
  $background: map.get($theme, background);

  .mat-list-item.mat-list-option {
    @include shadow.level-1(mat.get-color-from-palette($background, shadow-dark));

    &.hide-checkbox {
      .mat-pseudo-checkbox {
        visibility: hidden;
      }
    }
  }

  .mat-list-option:not(.mat-list-item-disabled),
  .mat-list-option.mat-list-item-disabled {
    background-color: mat.get-color-from-palette($background, card);
  }

  @include mat.legacy-list-theme($theme);
}
