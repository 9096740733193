@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/shadow";
@use "src/styles/theming/colors";

@mixin app-expansion-panel-theme($theme) {
  $background: map.get($theme, background);
  $margin-top-size: 1px;

  @include mat.expansion-theme($theme);

  .mat-accordion {
    > .mat-expansion-panel {
      margin-top: $margin-top-size;
    }
  }

  .mat-expansion-panel {
    margin-top: $margin-top-size;

    &:not([class*="mat-elevation-z"]) {
      @include shadow.level-1(mat.get-color-from-palette($background, shadow-dark));
    }
  }

  .mat-accordion {
    &.mat-expansion-panel {
      margin-top: $margin-top-size;
    }

    > .mat-expansion-panel-spacing.mat-expanded:last-child {
      margin-top: $margin-top-size;
    }
  }

  .mat-expansion-panel-body > ul > .grey-text {
    color: mat.get-color-from-palette($grey-disabled) !important;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      background-color: mat.get-color-from-palette($grey-subtle) !important;
    }
  }

  .mat-expansion-panel > .chosen-row {
    background-color: mat.get-color-from-palette($ox-beige-lighter) !important;
    &:hover {
      background-color: mat.get-color-from-palette($ox-beige-light) !important;
    }
  }

  .mat-expansion-panel-spacing.mat-expanded {
    margin-bottom: 0;
  }

  $arrow-size: 0.4em;
  .mat-expansion-indicator::after {
    width: $arrow-size;
    height: $arrow-size;
    color: colors.$primary;
  }
}
