@use "@angular/material" as mat;
@use "sass:map";

@mixin app-mat-accordion-stb-styling-theme($theme) {
  $foreground: map.get($theme, foreground);

  mat-accordion.stb-styling {
    .mat-expansion-panel {
      box-shadow: none;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: mat.get-color-from-palette($foreground, border-light);

      .mat-expansion-panel-header {
        height: auto;
        font-weight: bold;
        padding: 0.75em 0em !important;
        column-gap: 1em;

        .mat-expansion-indicator {
          margin: 0.25em;
        }
      }

      .mat-expansion-panel-body {
        padding: 0 0 1em;
      }
    }
  }
}
