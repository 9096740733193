@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/breakpoint";
@use "src/styles/theming/palette.scss";

$inactive: map.get(palette.$stb-grey, 500);
$on-surface: map.get(palette.$stb-grey, 800);

@mixin font-family($family, $color: $on-surface) {
  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: normal;
  text-align: start;
  font-family: $family, sans-serif;
  color: $color;
}

@mixin button() {
  @include font-family("Open Sans");
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
