$screen-md-min: 600px;
$screen-lg-min: 840px;
$screen-xl-min: 1176px;

@mixin for-print {
  @media print {
    @content;

    html,
    body {
      overflow-y: visible !important;
    }
  }
}

@mixin for-mobile {
  @media screen and (max-width: $screen-md-min) {
    @content;
  }
}

@mixin for-tablet {
  @media screen and (min-width: $screen-md-min) {
    @content;
  }

  @include for-print {
    @content;
  }
}

@mixin for-small-tablet {
  @include for-tablet {
    @content;
  }
}

@mixin for-large-tablet {
  @media screen and (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin for-desktop {
  @media screen and (min-width: $screen-xl-min) {
    @content;
  }
}
