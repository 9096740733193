stb-link-list {
  li {
    border-top: none !important;
  }

  i {
    display: none !important;
  }
}

stb-link-list:last-of-type a {
  border-bottom: none !important;
}
