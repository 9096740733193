@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/breakpoint";
@use "src/styles/utils";

@mixin app-account-page-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  app-account-page {
    .balance-header {
      background-color: mat.get-color-from-palette($accent, 50);

      .account-number {
        border-bottom-color: mat.get-color-from-palette($accent, 100);
      }
    }

    .transactions-title {
      background-color: mat.get-color-from-palette($primary, 50);
    }

    @include breakpoint.for-tablet {
      .transactions-title {
        background-color: transparent;
      }
    }

    .link-bank {
      border-color: mat.get-color-from-palette($primary, 50);
      color: mat.get-color-from-palette($foreground, text-ox);

      &:hover {
        border-color: mat.get-color-from-palette($primary, 100);
      }
    }

    .account-number {
      color: mat.get-color-from-palette($foreground, primary);
    }

    .get-started-container {
      @include utils.storeblocks-border(mat.get-color-from-palette($accent, 50), dashed);
    }
  }
}
