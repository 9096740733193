@use "@angular/material" as mat;
@use "sass:map";

@mixin app-number-circle-theme($theme) {
  $background: map.get($theme, background);

  app-number-circle {
    background-color: mat.get-color-from-palette($background, background);
    border-color: mat.get-color-from-palette($background, subtle-border);
  }
}
