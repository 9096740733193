@use "@angular/material" as mat;
@use "sass:map";

@mixin app-my-pension-landing-page-theme($theme) {
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);
  $white: mat.get-color-from-palette($background, white);

  app-my-pension-landing-page {
    background-color: $white;

    .faq-section {
      a {
        color: mat.get-color-from-palette($foreground, text-ox);
        &:visited {
          color: mat.get-color-from-palette($foreground, text-ox);
        }
        &:hover {
          color: mat.get-color-from-palette($foreground, primary);
        }
      }
    }
  }
}
