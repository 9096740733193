@use "@angular/material" as mat;
@use "sass:map";

@mixin app-default-contact-page-theme($theme) {
  $primary: map.get($theme, primary);
  $light: mat.get-color-from-palette($primary, 200);
  $dark: mat.get-color-from-palette($primary, 300);

  $accent: map.get($theme, accent);

  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  app-default-contact-page {
    section {
      app-quote-hero {
        background: $light;
        background: linear-gradient(180deg, $light 0%, $dark 100%);
      }

      background-color: mat.get-color-from-palette($background, white);

      .flex {
        background-color: mat.get-color-from-palette($primary, 100);
      }

      a {
        color: mat.get-color-from-palette($background, grey-700);
      }
    }
  }
}
