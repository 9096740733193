@use "@angular/material" as mat;
@use "sass:map";

@mixin app-engagement-list-public-theme($theme) {
  $primary: map.get($theme, primary);

  app-engagement-list-public {
    .consent {
      border-color: mat.get-color-from-palette($primary, 300);
      color: mat.get-color-from-palette($primary, 600);

      button {
        border-color: mat.get-color-from-palette($primary, 600) !important;
        color: mat.get-color-from-palette($primary, 600) !important;
      }
    }
  }
}
