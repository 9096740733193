@use "@angular/material" as mat;
@use "sass:map";

@mixin app-tip-banner-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  app-tip-banner {
    .tip {
      background-color: mat.get-color-from-palette($accent, 50);
      border-color: mat.get-color-from-palette($accent, 100);

      &.tip-mode-subtle {
        background-color: mat.get-color-from-palette($primary, 100);
        border-color: mat.get-color-from-palette($primary, 200);
      }
    }
  }
}
