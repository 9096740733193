@use "@angular/material" as mat;
@use "sass:map";

@mixin app-agreement-payout-guide-theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  app-agreement-payout-guide {
    .mat-expansion-panel-body {
      height: 100%;
    }

    .steps-vertical-line {
      background: mat.get-color-from-palette($foreground, border-light);
    }

    .steps-wrapper {
      .step-repeater {
        &:last-child {
          background-color: mat.get-color-from-palette($background, white);
        }

        .circle-icon {
          background-color: mat.get-color-from-palette($primary, 100);
          color: mat.get-color-from-palette($background, grey-700);
          border-color: mat.get-color-from-palette($background, white);
        }
        .step-content {
          .account-number {
            color: mat.get-color-from-palette($foreground, secondary-text);
          }
        }
      }
    }
  }
}
