@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/breakpoint";

@mixin app-shortcuts-theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);

  app-shortcuts {
    .shortcuts {
      a {
        color: mat.get-color-from-palette($foreground, text-ox);
        border-color: mat.get-color-from-palette($primary, 50);

        &:hover {
          border-color: mat.get-color-from-palette($primary, 100);
        }

        .arrow {
          color: mat.get-color-from-palette($foreground, primary-variant);
        }
      }
    }
  }
}
