@use "@angular/material" as mat;
@use "sass:map";

@mixin app-external-engagement-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  app-external-engagement {
    .form-validation-error-background {
      background-color: mat.get-color-from-palette($foreground, error);

      .form-validation-error-content {
        .form-validation-error-icon {
          color: mat.get-color-from-palette($background, white);
        }

        .form-validation-error-text {
          color: mat.get-color-from-palette($background, white);
        }
      }
    }
  }
}
