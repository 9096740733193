@use "@angular/material" as mat;
@use "sass:map";

@mixin app-form-field-theme($theme) {
  @include mat.legacy-form-field-theme($theme);
  $background: map.get($theme, background);

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: mat.get-color-from-palette($background, card);
  }
}
