@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/theming/typography";

@mixin app-typography-theme($theme) {
  $foreground: map.get($theme, foreground);

  .stb-typography {
    button {
      @extend .stb-button;
    }

    .stb-button {
      @include typography.button();
    }
  }
}
