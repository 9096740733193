mat-expansion-panel {
  border-radius: 0 !important;

  .mat-expansion-panel-header {
    padding: 0.5em 1em;

    &[aria-disabled="true"] {
      color: var(--color-on-background);
    }
  }

  .mat-expansion-panel-body {
    padding: 0 0.5em 1em 1em;
    color: var(--color-on-background);
  }
}

.mat-checkbox-inner-container {
  height: 1.2em !important;
  width: 1.2em !important;
}
