@use "@angular/material" as mat;
@use "sass:map";

@mixin app-smart-account-campaign-theme($theme) {
  $primary: map.get($theme, primary);

  app-smart-account-campaign {
    .campaign {
      border-color: mat.get-color-from-palette($primary, 100);
    }
  }
}
