.mat-bottom-sheet-container {
  @media (min-width: 992px) {
    max-width: calc(62em - 15em);
  }
}

.mat-bottom-sheet-container-medium {
  width: calc(62em - 15em);
}
.mat-bottom-sheet-container-large {
  width: calc(62em - 15em);
}
.mat-bottom-sheet-container-xlarge {
  width: calc(62em - 15em);
}
