@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils";
@use "src/styles/utils/shadow";
@use "src/styles/utils/breakpoint";

@mixin -highlight($color) {
  box-shadow: 0 0 12px $color;
}

@mixin -mat-expansion-panel($foreground) {
  mat-expansion-panel {
    box-shadow: none !important;

    mat-expansion-panel-header {
      color: var(--color-on-background);

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

@mixin -skeleton($primary, $background) {
  $skeleton-start: mat.get-color-from-palette($primary, 50);
  $skeleton-end: mat.get-color-from-palette($primary, 100);

  .app-engagement-skeleton {
    .engagement-panel {
      .skeleton-bar {
        background-color: $skeleton-start;
        animation-name: engagement-loading-pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }

    @keyframes engagement-loading-pulse {
      0% {
        background-color: $skeleton-start;
      }

      50% {
        background-color: $skeleton-end;
      }

      100% {
        background-color: $skeleton-start;
      }
    }
  }
}

@mixin app-contract-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  $highlight-color: mat.get-color-from-palette($foreground, info);

  app-contract {
    @include -skeleton($primary, $background);

    .engagement-panel {
      background-color: mat.get-color-from-palette($background, white);
      @include -mat-expansion-panel($foreground);

      &.highlight {
        animation-name: highlight-pulse;
        animation-duration: 6s;
        animation-timing-function: ease-in-out;
      }
    }

    .column-details {
      .details-name {
        .name-status {
          background-color: mat.get-color-from-palette($accent, 200);
          color: mat.get-color-from-palette($accent, 600);
        }
      }

      .details-description {
        color: mat.get-color-from-palette($foreground, secondary-text);
      }
    }
  }

  // These are duplicated on purpose to ensure a smooth transition
  // back to the original box-shadow at the end
  @keyframes highlight-pulse {
    $steps: (0, 15, 30, 45, 60, 75);

    @each $step in $steps {
      $index: index($steps, $step);

      #{$step}% {
        @if $index % 2 == 0 {
          @include -highlight(rgba($highlight-color, 0.35));
        } @else {
          @include -highlight($highlight-color);
        }
      }
    }

    100% {
      @include shadow.level-1(mat.get-color-from-palette($background, shadow-dark));
    }
  }
}
