@use "shadow";
@use "src/styles/theming/colors";

@mixin shadow($color) {
  @include shadow.level-1($color);
}

@mixin border-radius($radius: 4px) {
  border-radius: $radius;
}

@mixin border-radius-top($radius: 4px) {
  border-radius: 0;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-radius-bottom($radius: 4px) {
  border-radius: 0;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-radius-left($radius: 4px) {
  border-radius: 0;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-radius-right($radius: 4px) {
  border-radius: 0;
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

/*Explicit number of repeats because IE can't fit automatically.
High enough number to accoumpany most dynamic sizes of grids, 
but low enough to avoid memory budget warning in build*/
@mixin grid-template-rows {
  grid-template-rows: repeat(16, auto);
}

@mixin storeblocks-border($color: colors.$surface-border, $style: solid) {
  border: 4px $style $color;
}

@mixin storeblocks-agreements-border($color: colors.$surface, $style: solid) {
  border: 2px $style $color;
  border-radius: 0;
}
