@use "@angular/material" as mat;
@use "sass:map";

@mixin app-smart-account-theme($theme) {
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  app-smart-account {
    color: mat.get-color-from-palette($foreground, text-ox);
    background-color: mat.get-color-from-palette($background, white);

    .mat-typography h1 {
      color: mat.get-color-from-palette($foreground, text-ox);
    }
  }
}
