@use "@angular/material" as mat;
@use "sass:map";

@mixin app-expandable-alert-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);

  app-expandable-alert,
  app-expandable-error-alert {
    .title-wrapper {
      .title-icon {
        background-color: mat.get-color-from-palette($primary, default);
        color: mat.get-color-from-palette($background, white);

        &.error {
          background-color: mat.get-color-from-palette($background, warning);
        }
      }
    }
  }
}
