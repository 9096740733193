@use "sass:map";
@use "@angular/material" as mat;

@mixin shepherd-theme($theme) {
  $background: map.get($theme, background);
  $background-color: mat.get-color-from-palette($background, grey-700);
  $text-color: mat.get-color-from-palette($background, white);

  .shepherd-element {
    .shepherd-arrow {
      &:before {
        background: $background-color !important;
      }
    }

    .shepherd-content {
      background-color: $background-color;

      .shepherd-header {
        background-color: $background-color;
        padding-bottom: 0;

        .shepherd-title {
          color: $text-color;
          font-weight: 600;
        }

        .shepherd-cancel-icon {
          color: $text-color;
        }
      }

      .shepherd-text {
        color: rgba($text-color, 0.8);
      }
    }

    .shepherd-footer {
      padding: 1em;

      .shepherd-button {
        font-family: inherit;
        font-size: inherit;
        background: transparent;
        border: 1px solid $text-color;
        color: $text-color;

        &.shepherd-button-secondary {
          opacity: 0.7;
        }

        &:hover {
          background: transparent;
          color: $text-color;
        }
      }
    }
  }
}
