@use "sass:map";
@use "@angular/material" as mat;

@mixin app-withdrawal-order-engagement-details-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);

  app-withdrawal-order-engagement-details {
    .engagement-prognosis {
      background-color: mat.get-color-from-palette($primary, 100);
    }

    .engagement-sim-params {
      border-color: mat.get-color-from-palette($background, grey-100);
    }
  }
}
