@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/shadow";

@mixin app-menu-theme($theme) {
  $background: map.get($theme, background);

  app-menu {
    color: mat.get-color-from-palette($background, white);
    background-color: mat.get-color-from-palette($background, grey-700);
  }
}
