.mat-button-toggle-group,
.mat-button-toggle-standalone {
  overflow: unset !important; /* Workaround for misaligned toggle button near normal button. Ref: https://github.com/angular/material2/issues/8583 */
}

button.mat-icon-button {
  line-height: 1em; /*icons are not centered without*/
}

.small-left-icon {
  max-width: fit-content;
  width: auto;
  overflow: hidden; /*Backup if span is not used for button text*/

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    margin: 0 0.5em;

    mat-icon {
      width: 1em;
      margin-right: 0.5em;
      flex-shrink: 0;
      line-height: 0;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.mat-flat-button + .mat-button {
  margin-left: 1em;
}
