@use "@angular/material" as mat;
@use "sass:map";

@mixin app-add-engagement-buttons-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);

  .compact-add-engagements-buttons-mat-menu {
    background: mat.get-color-from-palette($background, white);

    &.mat-menu-panel {
      max-width: 350px;
    }

    .mat-menu-content {
      &:not(:empty) {
        padding: 0;
      }

      button {
        display: flex;
        width: 100%;
      }
    }
  }
}
