@use "@angular/material" as mat;
@use "sass:map";

@mixin app-advisor-page-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);

  .advisor-image-theme {
    background-color: mat.get-color-from-palette($background, shadow);
  }
}
