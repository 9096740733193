@use "@angular/material" as mat;
@use "sass:map";

@mixin app-purchase-assistance-consent-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  app-purchase-assistance-consent {
    button.opt-button {
      &:hover {
        color: mat.get-color-from-palette($primary, 500);
      }

      &:active {
        color: mat.get-color-from-palette($accent, 500);
      }
    }
  }
}
