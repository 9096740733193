@use "@angular/material" as mat;
@use "sass:map";

@mixin app-engagement-alert-message-theme($theme) {
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  app-engagement-alert-message {
    &.warning {
      background-color: mat.get-color-from-palette($background, background-warning);
      border-color: mat.get-color-from-palette($foreground, warning);
    }

    &.info {
      background-color: mat.get-color-from-palette($background, background-info);
      border-color: mat.get-color-from-palette($foreground, info);
    }

    &.error {
      background-color: mat.get-color-from-palette($background, background-error);
      border-color: mat.get-color-from-palette($foreground, error);
    }
  }
}
