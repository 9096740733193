@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/shadow";

// Todo: This theme file does not actually theme a component,
//  it just overrides MatCard styling. Consider doing this somewhere else
@mixin app-card-theme($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  @include mat.legacy-card-theme($theme);

  .mat-card {
    color: mat.get-color-from-palette($background, card-color) !important;
    @include shadow.level-1(mat.get-color-from-palette($background, shadow-dark));

    &:not([class*="mat-elevation-z"]) {
      @include shadow.level-1(mat.get-color-from-palette($background, shadow-dark));
    }

    > mat-card-title {
      font-size: 1em;
      border-bottom: 1px solid mat.get-color-from-palette($foreground, border-light);
      padding-bottom: 1em;
      margin-bottom: 1.25em;
    }

    mat-card-header {
      // Hack for removing margin created by a DOM element
      // in <mat-card> not reachable from this Sass file
      margin: 0 -1em;
    }

    .card-image {
      background-color: mat.get-color-from-palette($background, card-header);
    }
  }
}
