@use "@angular/material" as mat;
@use "sass:map";

@mixin app-bank-accounts-auth-step-up-theme($theme) {
  $background: map.get($theme, background);

  app-bank-accounts-auth-step-up {
    .mat-card {
      background-color: mat.get-color-from-palette($background, dialog) !important;
    }
  }
}
