@use "src/styles/utils/breakpoint";
@use "src/styles/theming/colors";

@mixin app-nav-progress-theme($theme) {
  app-nav-progress {
    mat-progress-bar {
      .mat-progress-bar-buffer {
        background-color: colors.$white !important;

        @include breakpoint.for-desktop {
          background-color: colors.$sp-grey-30 !important;
        }
      }
    }
  }
}
