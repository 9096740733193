@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/shadow";

// Overrides due to mat-button-toggle-theme() not letting us change certain colors using a theme.
// E.g. selected color is always picked from the static $foreground map
@mixin -mat-button-toggle($theme) {
  $primary: map.get($theme, primary);
  $selected-color: mat.get-color-from-palette($primary);

  mat-button-toggle-group {
    border-color: $selected-color;

    .mat-button-toggle-appearance-standard {
      background-color: transparent;
    }

    .mat-button-toggle-checked {
      background-color: $selected-color;
      color: mat.get-contrast-color-from-palette($primary, 500) !important;
    }
  }
}

@mixin app-simulation-params-panel-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);

  app-simulation-params-panel {
    .backdrop {
      background-color: rgba(mat.get-color-from-palette($background, grey-700), 0.6);
    }

    .panel {
      background-color: mat.get-color-from-palette($background, white);
      @include shadow.level-4(mat.get-color-from-palette($background, shadow-dark));
      @include -mat-button-toggle($theme);
    }
  }
}
