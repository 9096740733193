@use "@angular/material" as mat;
@use "sass:map";

@mixin app-engagement-details-public-pension-theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);

  app-engagement-details-public-pension {
    .payout-note {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }
}
