@use "@angular/material" as mat;
@use "sass:map";

@mixin app-concept-page-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $light: mat.get-color-from-palette($primary, 200);
  $dark: mat.get-color-from-palette($primary, 300);

  app-concept-page {
    a {
      color: mat.get-color-from-palette($foreground, text);
    }

    section {
      background-color: mat.get-color-from-palette($background, white);

      .priority-box {
        background-color: mat.get-color-from-palette($primary, 100);
      }

      app-quote-hero {
        background: $light;
        background: linear-gradient(180deg, $light 0%, $dark 100%);
      }
    }
  }
}
