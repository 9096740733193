@use "src/styles/utils/breakpoint";

mat-form-field {
  margin-right: 0.5em;
  margin-bottom: 1em;
  line-height: normal !important; //fixes slightly cropped formfield text

  &.app-form-field-compact {
    margin-right: 0;
    margin-bottom: 0;
  }
}

mat-error {
  line-height: normal; //fixes slightly cropped error text
}

@media (min-width: 330px) {
  mat-form-field,
  mat-select {
    width: 14.5em;

    &.app-form-field-block,
    &.app-select-block {
      width: 100%;
    }
  }
}
