@use "src/styles/theming/colors";

@mixin level-1($color: colors.$black) {
  box-shadow:
    0 0 2px 0 rgba($color, 0.07),
    0 2px 2px 0 rgba($color, 0.06),
    0 1px 3px 0 rgba($color, 0.14);
}

@mixin level-3($color: colors.$black) {
  box-shadow:
    0 3px 3px 0 rgba($color, 0.14),
    0 3px 4px 0 rgba($color, 0.12),
    0 1px 8px 0 rgba($color, 0.2);
}

@mixin level-4($color: colors.$black) {
  box-shadow:
    0 2px 4px 0 rgba($color, 0.14),
    0 4px 5px 0 rgba($color, 0.12),
    0 1px 10px 0 rgba($color, 0.2);
}

@mixin level-6($color: colors.$black) {
  box-shadow:
    0 6px 10px 0 rgba($color, 0.14),
    0 1px 18px 0 rgba($color, 0.12),
    0 3px 5px 0 rgba($color, 0.2);
}
