@use "@angular/material" as mat;
@use "sass:map";

@mixin chip-color($color, $palette) {
  $hue: 600;

  &.mat-#{$color} {
    border-color: mat.get-color-from-palette($palette, $hue);
    color: mat.get-color-from-palette($palette, $hue);

    &:focus::after {
      background-color: mat.get-color-from-palette($palette);
    }

    &.mat-chip-selected {
      background-color: mat.get-color-from-palette($palette, $hue);
      color: mat.get-color-from-palette($palette, "#{$hue}-contrast");
    }
  }
}

@mixin app-chips-theme($theme) {
  @include mat.legacy-chips-theme($theme);

  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);

  mat-chip {
    &.mat-chip {
      &.mat-standard-chip {
        cursor: pointer;
        border: 1px solid transparent;
        background-color: transparent;
        font-weight: bold;

        &::after {
          background-color: transparent;
        }

        @include chip-color(primary, $primary);
        @include chip-color(accent, $accent);
        @include chip-color(warn, $warn);
      }
    }
  }
}
