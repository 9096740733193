@use "@angular/material" as mat;
@use "sass:map";

@mixin app-simulation-age-theme($theme) {
  $background: map.get($theme, background);

  app-simulation-age {
    .part-time-hint {
      background-color: mat.get-color-from-palette($background, background-darker);
    }
  }
}
