@use "@angular/material" as mat;
@use "sass:map";

@mixin app-tabs-theme($theme) {
  @include mat.legacy-tabs-theme($theme);
  $background: map.get($theme, background);

  .mat-tab-group {
    background-color: mat.get-color-from-palette($background, card);
  }

  .mat-footer-row {
    background-color: mat.get-color-from-palette($background, background);
  }
}
