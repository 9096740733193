@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/breakpoint";

@mixin app-quote-hero-theme($theme) {
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  app-quote-hero {
    background-color: mat.get-color-from-palette($primary, 200);

    .quote-container {
      color: mat.get-color-from-palette($background, white);
      background-color: mat.get-color-from-palette($background, grey-700);

      h1 {
        color: mat.get-color-from-palette($background, white);
      }
    }

    @include breakpoint.for-tablet {
      .quote-container {
        background-color: mat.get-color-from-palette($foreground, disabled-button);
      }
    }
  }
}
