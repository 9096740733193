@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/shadow";

@mixin app-consent-card-theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  app-consent-card {
    @include shadow.level-1(mat.get-color-from-palette($background, shadow-dark));

    .text {
      background-color: mat.get-color-from-palette($background, white);
    }

    .value {
      background-color: mat.get-color-from-palette($primary, 100);

      &.accepted {
        color: mat.get-color-from-palette($foreground, success);
      }

      &.declined {
        color: mat.get-color-from-palette($foreground, error);
      }

      .label {
        color: mat.get-color-from-palette($foreground, text);
      }
    }
  }
}
