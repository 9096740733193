@use "@angular/material" as mat;
@use "sass:map";

@mixin app-checkbox-theme($theme) {
  $primary: map.get($theme, primary);

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: mat.get-color-from-palette($primary, 600);
  }
}
