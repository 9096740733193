@use "src/styles/theming/colors";
@use "src/styles/utils/shadow";
@use "src/styles/utils/breakpoint";

.withdrawal-slider-tooltip {
  position: relative;
  top: -5rem;
  max-width: unset !important;
  margin: 0 !important;
  white-space: pre-line;
  @include shadow.level-3(colors.$black);
  background: colors.$white;
  color: colors.$black !important;

  &::first-line {
    font-weight: bold;
  }
}

@include breakpoint.for-tablet {
  .withdrawal-slider-tooltip {
    left: 5em;
  }
}

@include breakpoint.for-desktop {
  .withdrawal-slider-tooltip {
    left: 10em;
  }
}
