@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/theming/colors";

@mixin app-skeleton-theme($theme) {
  $primary: map.get($theme, primary);
  $start-color-dark: colors.$beige-3;
  $end-color-dark: colors.$beige-5;
  $start-color-light: colors.$beige-4;
  $end-color-light: colors.$beige-6;
  $start-color-green: var(--color-surface-variant);
  $end-color-green: var(--color-green-30);

  app-text-skeleton .text-skeleton,
  app-list-skeleton .blob-skeleton,
  app-chart-skeleton .column-skeleton {
    background-color: $start-color-dark;
    animation-name: loading-pulse-dark;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &.light {
      background-color: $start-color-light;
      animation-name: loading-pulse-light;
    }

    &.green {
      background-color: $start-color-green;
      animation-name: loading-pulse-green;
    }
  }

  @keyframes loading-pulse-dark {
    0% {
      background-color: $start-color-dark;
    }
    50% {
      background-color: $end-color-dark;
    }
    100% {
      background-color: $start-color-dark;
    }
  }

  @keyframes loading-pulse-light {
    0% {
      background-color: $start-color-light;
    }
    50% {
      background-color: $end-color-light;
    }
    100% {
      background-color: $start-color-light;
    }
  }

  @keyframes loading-pulse-green {
    0% {
      background-color: $start-color-green;
    }
    50% {
      background-color: $end-color-green;
    }
    100% {
      background-color: $start-color-green;
    }
  }
}
