@use "src/styles/utils/breakpoint";
@use "src/styles/theming/colors";
@use "src/styles/utils/reset";
@use "src/styles/theming/typography";

$overlay-margin: 2em;

.cdk-global-scrollblock {
  position: static !important;
  overflow: hidden !important;
}

.fullscreen-mobile-dialog {
  @include breakpoint.for-mobile {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem !important;
    width: 100vw !important;
    height: 100% !important;
    min-height: 100% !important;
    box-sizing: border-box !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;

    [mat-dialog-close],
    mat-dialog-close,
    .mat-dialog-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    [mat-dialog-title],
    mat-dialog-title,
    .mat-dialog-title {
      max-height: 55px;
      overflow: hidden;
    }

    [mat-dialog-content],
    mat-dialog-content,
    .mat-dialog-content {
      height: 100% !important;
      max-height: calc(100vh - 180px);
    }

    [mat-dialog-actions],
    mat-dialog-actions,
    .mat-dialog-actions {
      max-height: 8rem;
      position: fixed;
      bottom: 0.5rem;
      left: 0.5rem;
      right: 0.5rem;

      [mat-dialog-close],
      mat-dialog-close,
      .mat-dialog-close {
        top: unset;
        right: unset;
      }
    }
  }
}

mat-dialog-container {
  @extend .fullscreen-mobile-dialog;

  overflow: hidden;
  max-width: 46rem;

  [mat-dialog-title],
  mat-dialog-title,
  .mat-dialog-title {
    border-bottom: 1px solid colors.$sp-grey-30;
    padding-bottom: 1rem;
    padding-right: 1.5em;
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  [mat-dialog-close],
  mat-dialog-close,
  .mat-dialog-close {
    @include reset.button;
    float: right;
  }

  [mat-dialog-content],
  mat-dialog-content,
  .mat-dialog-content {
    h4 {
      margin: 0 0 8px 0;
    }
    p {
      margin: 0 0 24px 0;
    }
    a {
      display: inline;
    }
  }

  [mat-dialog-actions],
  mat-dialog-actions,
  .mat-dialog-actions {
    border-top: 1px solid colors.$sp-grey-30;
    padding: 0;
    padding-top: 1rem;
    margin-bottom: 0;
    margin-top: 1rem;
  }
}

.large-dialog-padding {
  &.cdk-overlay-pane {
    max-width: 90vw !important; //Overrides default 80vw;
  }
}

.no-container-styling {
  mat-dialog-container {
    padding: 0;
    box-shadow: none;
    background: none;
    border-radius: 0;
  }
}

.no-max-width {
  max-width: 100% !important; // Overriding default max-width
}

.darker-backdrop {
  background: rgba(0, 0, 0, 0.75);
}

.app-steppable-modal {
  @extend .no-max-width;
}
