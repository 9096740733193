@use "src/styles/theming/colors" as *;
@use "@angular/material" as mat;
@use "sass:map";

$non: #ff00ff;

// DEPRECATED
$stb-aqua: (
  50: #e4f0f2,
  100: $sp-sky-20 /*light sky*/,
  200: $sp-sky-30 /*sky*/,
  300: #62a8b1,
  400: #1b8d9b /*water blue*/,
  500: #1e838f /*active input*/,
  600: #1a7b87,
  700: #16707c,
  800: #126672,
  900: #0a5360,
  A100: #93edff,
  A200: #60e4ff,
  A400: #2ddcff,
  A700: #14d7ff,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $black,
  ),
);

// DEPRECATED
$stb-data: (
  50: $sp-sky-30 /*05*/,
  100: #99cccc /*04*/,
  200: #75b5bd /*03*/,
  300: #52a1ab /*02*/,
  400: #268c99 /*01*/,
  500: #c9c9ba /*10*/,
  600: #a6ab99 /*09*/,
  700: #828f75 /*08*/,
  800: $sp-moss-60 /*07*/ /*moss*/,
  900: #4f5c4a /*06*/,
  A100: #52a1ff,
  A200: #268cff,
  A400: #5fff57,
  A700: #4fff4a,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $black,
  ),
);

// DEPRECATED
$stb-brown: (
  50: $sp-brown-10,
  100: $sp-brown-20 /* light sand */,
  200: $sp-brown-30 /* dark sand */,
  300: $sp-brown-40,
  400: $sp-brown-50,
  500: $sp-brown-60,
  600: $sp-brown-70 /* dust brown */,
  700: $sp-brown-80,
  800: $sp-brown-90,
  900: $non,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $non,
  ),
);

// DEPRECATED
$stb-green: (
  50: $non,
  100: $non,
  200: $sp-green-20 /* Pastel Green */,
  300: $non,
  400: $sp-green-50 /* light green */,
  500: $sp-green-60 /* forest green */,
  600: $sp-green-70 /* darker forest */,
  700: #5f7420,
  800: $non,
  900: $non,
  contrast: (
    50: $non,
    100: $non,
    200: $black,
    300: $non,
    400: $black,
    500: $sp-grey-80,
    600: $black,
    700: $non,
    800: $non,
    900: $non,
  ),
);

// DEPRECATED
$stb-red: (
  50: $sp-red-20 /*lightest red*/,
  100: $sp-red-30 /*lighter red*/,
  200: $non,
  300: $non,
  400: $non,
  500: $red-4 /*Primary red*/,
  600: $non,
  700: $sp-red-60 /*Error*/,
  A100: #ffdddd,
  A200: $sp-red-30,
  A400: #ff291c,
  A700: #ff1b18,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);

// DEPRECATED
$stb-support: (
  50: $white /*  */,
  100: $white /*  */,
  200: $sp-brown-20 /* light sand */,
  300: #e6e0d7 /* dark sand */,
  400: $sp-yellow-20 /* vanilla */,
  600: $sp-yellow-30 /* dark vanilla */,
  700: $sp-green-50 /*light green*/ /*button*/,
  800: $sp-green-60 /*forest green*/,
  900: $sp-green-70 /*darker forest*/,
  A100: $white,
  A200: $white,
  A400: $white,
  A700: $white,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $black,
    600: $black,
    700: $black,
    800: $white,
    900: $white,
    A100: $black,
    A200: $black,
    A400: $black,
    A700: $black,
  ),
);

// DEPRECATED
$stb-grey: (
  50: #fff,
  100: $sp-grey-10 /*background*/,
  200: $sp-grey-30 /*grey 4*/,
  300: $sp-grey-40 /*grey 3*/,
  400: $sp-grey-50 /*grey 2*/,
  500: $sp-grey-60 /*grey 1*/,
  600: $sp-grey-70 /*charcoal*/,
  700: $sp-grey-80,
  800: $charcoal,
  900: $black,
  A100: $black,
  A200: $black,
  A400: $black,
  A700: $black,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

//Q3 2021 colors. 2021 the year of the Ox:

$ox-beige: (
  50: $beige-2,
  100: $beige-3,
  200: $beige-4,
  300: $beige-5,
  400: $beige-6,
  500: $beige-7,
  600: $beige-8,
  700: $beige-9,
  800: $non,
  900: $non,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $black,
    600: $black,
    700: $white,
    800: $non,
    900: $non,
  ),
);

$ox-blue: (
  50: #c3f2f6,
  100: #abf0f4,
  200: #94eef2,
  300: #41dee8,
  400: #00b3c5,
  500: $blue-3,
  600: #028799,
  700: #006e7f,
  800: #025563,
  900: $blue-4,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $black,
    400: $black,
    500: $black,
    600: $black,
    700: $white,
    800: $white,
    900: $white,
  ),
);

$ox-green: (
  50: $green-1,
  100: $green-2,
  200: $green-3,
  300: $non,
  400: $non,
  500: $green-5,
  600: $non,
  700: $non,
  800: $green-7,
  900: $non,
  contrast: (
    50: $black,
    100: $black,
    200: $black,
    300: $non,
    400: $non,
    500: $black,
    600: $non,
    700: $non,
    800: $white,
    900: $non,
  ),
);

$ox-red: (
  50: $non,
  100: $non,
  200: $non,
  300: $red-4,
  400: $red-5,
  500: $red-6,
  600: $red-7,
  700: $non,
  800: $non,
  900: $non,
  contrast: (
    50: $non,
    100: $non,
    200: $non,
    300: $white,
    400: $white,
    500: $white,
    600: $white,
    700: $non,
    800: $non,
    900: $non,
  ),
);

/************************************************************
 *
 * Overriding $light-theme-background-palette and $light-theme-foreground-palette is not really
 * a feature supported by Angular Material (anymore). Unless we rewrite this stuff, we got to anyway.
 * See declined-ish PR: https://github.com/angular/components/pull/19963
 *
 * This is a workaround and it makes our entire theme setup fragile. Changing the way `palette.scss` is imported
 * in `app-theme.scss` may cause problems. Change with caution.
 *
 ************************************************************/

@import "@angular/material";

// Some Angular Material components require certain colors to be defined in a theme.
$mat-components-light-theme-background: (
  tooltip: map.get($stb-grey, 500),
);

$light-theme-background-palette: map.merge(
  (
    status-bar: map.get(mat.$grey-palette, 300),
    app-bar: map.get(mat.$grey-palette, 100),
    dashboard-background: map.get(mat.$grey-palette, 50),
    white: map.get($stb-grey, 50),
    background: map.get($stb-brown, 50),
    hover: rgba(black, 0.04),
    grey-700: map.get($stb-grey, 700),
    grey-100: map.get($stb-grey, 100),
    card: map.get($stb-grey, 50),
    card-color: map.get($stb-grey, 600),
    card-header: #f9f9f9,
    link-list-divider: map.get($stb-grey, 300),
    subtle-border: map.get($stb-grey, 400),
    dialog: white,
    disabled-button: rgba(black, 0.12),
    raised-button: white,
    focused-button: rgba(black, 0.12),
    selected-button: map.get(mat.$grey-palette, 300),
    selected-disabled-button: map.get(mat.$grey-palette, 400),
    disabled-button-toggle: map.get(mat.$grey-palette, 200),
    unselected-chip: map.get(mat.$grey-palette, 300),
    disabled-list-option: map.get(mat.$grey-palette, 200),
    shadow: map.get($stb-grey, 300),
    shadow-dark: map.get($stb-grey, 900),
    background-darker: map.get($stb-grey, 200),
    background-message: map.get($stb-support, 200),
    background-warning: map.get($stb-support, 400),
    background-error: map.get($stb-red, 50),
    background-info: map.get($stb-aqua, 100),
    background-success: map.get($stb-aqua, 100),
    border-darker: map.get($stb-grey, 300),
    image-overlay: rgba($sp-grey-10, 0.7),
    calculations-table-border: map.get($stb-grey, 700),
    table-footer-background: map.get($stb-grey, 100),
    advice-pdf-panel-header-text: map.get($stb-grey, 900),
    button-group-toggle-background: map.get($stb-aqua, 200),
    positive-amount: map.get($stb-aqua, 300),
    negative-amount: map.get($stb-red, 500),
    sand-background: map.get($stb-support, 200),
    delta-background: map.get($stb-data, 100),
    not-selected: map.get($stb-grey, 500),
    warning: #f49342 /* non-existng color */
      /** Hold your horses, partner. This property has grown too large already.
     * Do you really need yet another general color? It's probably already
     * defined, or you're not using it correctly.
     */,
  ),
  $mat-components-light-theme-background
);

$light-theme-foreground-palette: (
  base: black,
  divider: rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.58),
  disabled-text: rgba(black, 0.38),
  elevation: black,
  secondary-link: map.get($stb-green, 700),
  hint-text: rgba(black, 0.38),
  secondary-text: map.get($stb-grey, 500),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: map.get($stb-grey, 600),
  text-darker: map.get($stb-grey, 700),
  text-ox: map.get($stb-grey, 800),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
  shadow: map.get($stb-grey, 300),
  border-light: map.get($stb-grey, 200),
  border-bottom-expansion: rgba(black, 0.12),
  active-border: map.get($stb-aqua, 500),
  outline: #5e9ed6,
  success: map.get($stb-green, 600),
  error: map.get($stb-red, 700),
  warning: #c17b0b,
  warning-background: #fdf6cf,
  info: $sp-aqua-70,
  savings-list: map.get($stb-support, 600),
  employment-list: map.get($stb-data, 200),
  public-list: map-get($stb-brown, 500),
  illustration: map.get($ox-red, 300),
  primary: map.get($ox-red, 400),
  primary-variant: map.get($ox-red, 500),
  primary-pressed: map.get($ox-red, 600),
);
