@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/shadow";

@mixin app-amount-card-theme($theme) {
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);

  app-amount-card {
    .amount-card {
      background-color: mat.get-color-from-palette($background, white);

      .percentage-positive {
        color: mat.get-color-from-palette($accent, 500);
        &:before {
          content: "➤";
          display: inline-block;
          transform: rotate(270deg);
        }
      }

      .percentage-negative {
        color: mat.get-color-from-palette($background, negative-amount);
        &:before {
          content: "➤";
          display: inline-block;
          transform: rotate(90deg);
        }
      }
    }
  }
}
