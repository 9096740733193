@use "@angular/material" as mat;
@use "sass:map";
@use "src/styles/utils/shadow";

@mixin app-lens-radio-button-theme($theme) {
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  app-lens-radio-button {
    @include shadow.level-3(mat.get-color-from-palette($background, shadow-dark));

    &:hover {
      @include shadow.level-4(mat.get-color-from-palette($background, shadow-dark));
    }

    .lens {
      .head {
        background: mat.get-color-from-palette($background, card-header);

        img {
          color: mat.get-color-from-palette($background, grey-700);
        }
      }

      .corner {
        border-top-color: mat.get-color-from-palette($background, white);
      }

      .dot {
        background: mat.get-color-from-palette($background, shadow);
      }

      .inner-border {
        box-shadow: inset 0px 0px 0px 0px mat.get-color-from-palette($foreground, active-border);
      }

      .text {
        background: mat.get-color-from-palette($background, white);
      }

      &.checked:not(.isLoading) {
        &:not(.useDarkMode) {
          .corner {
            border-top-color: mat.get-color-from-palette($foreground, active-border);
          }

          .dot {
            background: mat.get-color-from-palette($background, white);
          }

          .inner-border {
            box-shadow: inset 0px 0px 0px 2px mat.get-color-from-palette($foreground, active-border);
          }
        }

        &.useDarkMode {
          .head {
            background: mat.get-color-from-palette($foreground, active-border);

            img {
              filter: invert(1);
            }
          }

          .dot {
            background: mat.get-color-from-palette($foreground, active-border);
          }

          .inner-border {
            display: none;
          }
        }
      }
    }
  }
}
