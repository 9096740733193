@use "src/styles/theming/typography";

.custom-form-wrapper {
  .stb-form-wrapper {
    margin: 0;

    .label {
      display: none;
    }

    div.input {
      margin-top: 0;
    }
  }
}

.large-minimal-select {
  @extend .custom-form-wrapper;

  .stb-form-wrapper {
    select {
      min-width: 9rem;
    }
  }
}

.fit-content-select {
  @extend .large-minimal-select;

  .stb-form-wrapper {
    select {
      flex-grow: unset !important;
    }
  }
}
