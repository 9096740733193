@use "src/styles/utils/z-index";
@use "src/styles/theming/colors";

mat-slide-toggle {
  .mat-slide-toggle-label {
    margin: 0;
  }

  .mat-slide-toggle-bar {
    height: 20px;
    border-radius: 20px;
    background-color: colors.$unselected;

    .mat-slide-toggle-thumb-container {
      top: 1px;
      z-index: z-index.$bottom !important;

      .mat-slide-toggle-thumb {
        width: 18px;
        height: 18px;
        background-color: colors.$white !important;
        box-shadow: none;
        border: 2px solid colors.$inactive;
      }
    }
  }

  .mat-slide-toggle-ripple {
    display: none;
  }

  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: colors.$beige-8 !important;

      .mat-slide-toggle-thumb-container {
        transform: translate3d(17px, 0, 0);

        .mat-slide-toggle-thumb {
          border-color: colors.$beige-8;
        }
      }
    }
  }
}
