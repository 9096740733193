@use "@angular/material" as mat;
@use "sass:map";

@mixin app-public-pension-contract-badge-theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);

  app-public-pension-contract-badge {
    background-color: mat.get-color-from-palette($primary, 100);
    color: mat.get-color-from-palette($primary, 600);
  }
}
