@use "@angular/material" as mat;
@use "sass:map";

@mixin app-active-employment-card-theme($theme) {
  $primary: map.get($theme, primary);

  app-active-employment-card {
    .active-card {
      background-color: mat.get-color-from-palette($primary, 50);
    }
  }
}
