@use "@angular/material" as mat;
@use "src/styles/utils/breakpoint";
@use "sass:map";

@mixin app-tip-icon-theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);

  app-tip-icon {
    button {
      color: inherit;
    }
  }

  .mat-tooltip.storeblocks-styling {
    background-color: mat.get-color-from-palette($foreground, text-ox);
    padding: var(--spacer-m);

    @include breakpoint.for-desktop {
      width: 100%;
      max-width: 480px;
    }
  }

  // Takes care of positioning itself inside <mat-form-field>
  mat-form-field {
    app-tip-icon {
      position: absolute;
      margin: -0.75em 0 1em 1em;
    }
  }

  // Takes care of positioning itself inside <label>
  label {
    app-tip-icon {
      margin-left: 0.5em;

      button {
        position: relative;
        width: 1em;
        height: 1em;
      }
    }
  }
}
