@use "@angular/material" as mat;
@use "sass:map";

@mixin app-public-pension-contract-theme($theme) {
  $foreground: map.get($theme, foreground);

  app-public-pension-contract {
    .summary-table {
      .summary-row {
        .summary-label {
          color: mat.get-color-from-palette($foreground, text);

          .summary-label-subtitle {
            color: mat.get-color-from-palette($foreground, secondary-text);
          }
        }
      }
    }
  }
}
