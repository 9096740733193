button[stb-btn-ng] {
  white-space: inherit !important;
  min-height: 40px;
  height: auto !important;
  text-align: center !important;
}

button[stbButton="text"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
