@use "sass:map";
@use "src/styles/utils/breakpoint";
@use "src/styles/theming/colors";

@import url("https://assets.storebrand.no/css/index.css");
@import "shepherd.js/dist/css/shepherd.css";
@import "@storeblocks/global-styles-ng/assets/_utils.scss";

html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

html > body {
  margin-right: 0 !important; //Overrides scrollbar compansation from menu navbar
}

html {
  overflow-y: auto;
  box-sizing: border-box !important;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--spacer-s);

  @include breakpoint.for-desktop {
    max-width: #{breakpoint.$screen-xl-min};
  }
}

a:has(button) {
  text-decoration: none;
}

.snackbar-alert {
  .mat-simple-snack-bar-content,
  .mat-simple-snackbar-action {
    color: white;
    font-size: 0.875em;
  }

  .mat-simple-snackbar-action .mat-button {
    font-weight: 600;
  }
}

i,
span[class^="icon-"] {
  min-width: 16px;
}

label {
  display: block;
  margin: 0 0 0.5em;
}

form {
  margin-top: 2em;
}

app-root {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%; // Required to prevent overflowing widths in IE 11

  > * {
    flex-shrink: 0;
  }

  // Make every page component stretch as much as they can in height
  // to easier enable 100% height inside pages
  router-outlet + * {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

#footer-root {
  a {
    color: colors.$red-5;
  }
}

.dev {
  background-color: #333;
  color: white;
  box-shadow: 1px 3px 4px 2px rgba(173, 173, 173, 1);
}

.anchor-stbButton-wrapper {
  text-decoration: none;
}

.full-page-width-limiter {
  max-width: 44.5em;
}

.show-only-on-print {
  display: none !important;
}

.text-short,
.text-short-bold,
.text-short-italics {
  line-height: 20px; //Change from 16px to avoid cropping
}

@include breakpoint.for-print {
  .hide-only-on-print {
    display: none !important;
  }

  .show-only-on-print {
    display: block !important;
  }
}

@import "styles/overrides/mat-form-field";
@import "styles/overrides/mat-bottom-sheet";
@import "styles/overrides/mat-expansion-panel";
@import "styles/overrides/mat-button";
@import "styles/overrides/mat-dialog";
@import "styles/overrides/mat-checkbox";
@import "styles/overrides/mat-slide-toggle";

@import "styles/overrides/stb-button";
@import "styles/overrides/stb-link-list";
@import "styles/overrides/stb-form";

@import "styles/a11y.scss";
@import "styles/theming/app-theme.scss";
@import "styles/page-init-spinner.scss";
@import "styles/withdrawal-order-slider-tooltip.scss";

body {
  .styles__surveyContainer {
    width: 100% !important;

    ._hj-widget-container div[role="dialog"] .styles__surveyContainer {
      box-shadow: none !important;
    }

    .inlineSurvey {
      padding: 0px !important;
    }

    .surveyTitle,
    p.thankYouMessage {
      font-family: font-family("Open Sans") !important;
      font-weight: normal !important;
      font-size: 18px !important;
      line-height: 27px !important;
    }

    button.primaryButton.surveyActionButton {
      font-family: font-family("Open Sans") !important;
      font-weight: normal !important;
      font-size: 18px !important;
      line-height: 27px !important;
    }

    button.closeButton.surveysPrimaryButton {
      font-family: font-family("Open Sans") !important;
      font-weight: normal !important;
      font-size: 14px !important;
    }

    textarea.__styles__inputField {
      font-size: 18px !important;
    }
  }
}
