@use "@angular/material" as mat;
@use "sass:map";

@mixin app-transactions-theme($theme) {
  $primary: map.get($theme, primary);
  $foreground: map.get($theme, foreground);
  $background: map.get($theme, background);

  app-transactions {
    .has-no-transactions {
      border-color: mat.get-color-from-palette($primary, 100);
    }

    table {
      &.mobile-transactions {
        border-top-color: mat.get-color-from-palette($background, border-darker);
      }

      thead,
      tbody {
        tr {
          th {
            background-color: mat.get-color-from-palette($primary, 50);
          }

          th,
          td {
            border-bottom-color: mat.get-color-from-palette($background, border-darker);

            .transaction-date {
              color: mat.get-color-from-palette($foreground, secondary-text);
            }
          }
        }
      }
    }
  }
}
